import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { Card } from '~/interfaces/users'

interface GetResponse {
  cards: Card[]
}

export const useCards = <SData>(select: (response: GetResponse) => SData) => {
  const privateAxios = usePrivateAxiosInstance()
  const {userCredentials} = useUserCredentials()


  return useQuery({
    queryKey: ['cards'],
    queryFn: async () => {
      const response = await privateAxios.get<GetResponse>('user/cards')

      return response.data
    },
    select,
    enabled: userCredentials.value.isAuthenticated,
  })
}

export const useInvalidateCarts = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({
      queryKey: ['cards'],
    })
  }
}

export const useDeleteCart = () => {
  const privateAxios = usePrivateAxiosInstance()
  const invalidate = useInvalidateCarts()

  return useMutation({
    mutationFn: async (vars: any) => {
      const response = await privateAxios.delete('user/card', {
        params: {
          id: vars.id,
        },
      })
      return response.data
    },
    onSuccess: invalidate,
  })
}
