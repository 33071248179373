<template>
  <div class="relative flex h-full w-full transform flex-col items-stretch overflow-y-auto bg-whitegray pt-8">
    <MyLocationDialog
      :show="showMyLocationDialog"
      @close="closeMyLocationDialog"
      @location-changed="emit('backToCart')"
    />
    <div class="flex grow flex-col items-stretch px-4">
      <strong class="mb-4 text-2xl font-medium">Оформление заказа</strong>

      <div class="flex grow flex-col items-stretch gap-4 self-stretch">
        <div class="rounded-xl bg-current bg-gray p-1.5 text-sm">
          <div class="relative">
            <div
              class="absolute bottom-0 left-0 top-0 w-1/2 rounded-lg bg-white shadow-main transition-transform"
              :class="{
                'translate-x-0': receptionWay?.type === 'delivery',
                'translate-x-full': receptionWay?.type === 'restaurant',
              }"
            ></div>
            <button
              class="hover isolate h-10 w-1/2 rounded-lg"
              type="button"
              @click="showMyLocationDialog = true"
            >
              Доставка
            </button>
            <button
              class="isolate h-10 w-1/2 rounded-lg"
              type="button"
              @click="showMyLocationDialog = true"
            >
              Самовывоз
            </button>
          </div>
        </div>

        <div class="relative grow pt-4 pb-16">
          <Transition
            name="fade"
            mode="out-in"
          >
            <OrderDialogPaymentReceptionFormDelivery
              v-if="receptionWay?.type === 'delivery'"
              @location-changed="emit('backToCart')"
            />
            <OrderDialogPaymentReceptionFormRestaurant
              v-else-if="receptionWay?.type === 'restaurant'"
              @location-changed="emit('backToCart')"
            />
          </Transition>
        </div>
      </div>
    </div>

    <div class="bg-white px-4 py-8 shadow-main">
      <p class="mb-6 text-lg">Способ оплаты</p>

      <HeadlessRadioGroup v-model="selectedPayType">
        <OrderDialogInputRadio
          value="cash"
          label="Наличными"
        >
          <IconBlackWallet />
        </OrderDialogInputRadio>
        <div
          class="mb-2 grid grid-cols-1 grid-rows-[0fr] overflow-hidden transition-[grid-template-rows]"
          :class="{
            'grid-rows-[1fr]': selectedPayType === 'cash',
          }"
        >
          <div class="min-h-0">
            <div class="ml-8 py-2">
              <p class="mb-2 text-sm text-black text-opacity-50">С какой суммы подготовить сдачу?</p>
              <div class="flex items-center gap-4">
                <div
                  class="relative w-fit transition-opacity"
                  :class="{
                    'opacity-50': noCashback,
                  }"
                >
                  <input
                    v-model="cashback"
                    type="number"
                    class="w-[15ch] rounded-xl border border-transparent bg-black bg-opacity-10 px-4 py-3 pr-8 text-end outline-none transition-colors"
                    :class="{
                      '!border-red': !!cashbackError,
                    }"
                    :disabled="noCashback"
                  />
                  <span class="absolute right-4 top-1/2 -translate-y-1/2 select-none text-black text-opacity-50">
                    ₽
                  </span>
                </div>
                <label class="flex items-center gap-2">
                  <InputCheckbox name="no_cashback" />
                  <span class="cursor-pointer select-none"> Без сдачи </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <OrderDialogInputRadio
          class="mb-2"
          value="card"
          label="Картой при получении"
        >
          <IconCard />
        </OrderDialogInputRadio>
        <OrderDialogInputRadio
          class="mb-2"
          value="online"
          label="Картой на сайте"
        >
          <IconCard />
        </OrderDialogInputRadio>
      </HeadlessRadioGroup>

        <div class="container mx-auto" v-if="selectedPayType == 'online'">
            <div class="w-72">
                <HeadlessListbox v-model="selectedCard">
                    <div class="relative mt-1">
                        <HeadlessListboxButton
                            class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                        >
                            <span class="block truncate">{{ selectedCard?.number }}</span>
                            <span
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                            >
                                <ChevronDownIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
            </span>
                        </HeadlessListboxButton>

                        <transition
                            leave-active-class="transition duration-100 ease-in"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <HeadlessListboxOptions
                                class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                                <HeadlessListboxOption
                                    v-for="card in cards"
                                    v-slot="{ active, selected }"
                                    :key="card.id"
                                    :value="card"
                                    as="template"
                                >
                                    <li
                                        :class="[
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                  ]"
                                    >
                  <span
                      :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                  >{{ card.number }}</span>
                                        <span
                                            v-if="selected"
                                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                        >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                                    </li>
                                </HeadlessListboxOption>
                            </HeadlessListboxOptions>
                        </transition>
                    </div>
                </HeadlessListbox>
            </div>
        </div>

      <div class="mt-8 flex w-full gap-4 font-medium">
        <button
          class="flex grow items-center justify-center rounded-xl bg-gray px-2 py-4 text-xs uppercase leading-none"
          type="button"
          @click="emit('backToCart')"
        >
          Назад в корзину
        </button>
        <SimpleButton
          class="grow px-4 py-4 text-xs uppercase"
          type="submit"
          :disabled="props.isFormSubmitDisabled"
        >
          Оформить заказ на {{ props.basket?.total_sum }} &#8381;
          <IconArrowRight class="inline h-4" />


            <div v-if="props.isFormSubmitDisabled"
                class="ms-2 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                role="status">
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
            </div>
        </SimpleButton>
      </div>
    </div>

    <span class="absolute top-2 left-0 right-0 text-center text-sm text-black opacity-50 md:hidden"
      >Свайпай вправо, чтобы закрыть</span
    >
  </div>
</template>

<script setup lang="ts">
import { useCards } from '~/composables/api/cards'

import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/20/solid'
import creditCardType from 'credit-card-type'
import type { GetBasketResponse } from '~/composables/api/basket'

const props = defineProps<{
    isFormSubmitDisabled?: boolean
    basket?: GetBasketResponse
}>()

const emit = defineEmits(['backToCart'])

const { data: receptionWay } = useCurrentReceptionWay()


const selectedCard = ref()

const { data: cards } = useCards((v) => {
    let newCard = {id: 0, number: "Новая карта", name: "Новая карта", is_selected: true},
        cards = [newCard]

    if (v.cards.length > 0) {
        cards = [...cards, ...v.cards]
        newCard.is_selected = false
    }

    selectedCard.value = cards.find(el => el.is_selected);
    return cards
})

watch(selectedCard, () => {
    selectedCardId.value = selectedCard.value.id
})

const noCashback = useFieldValue<boolean | undefined>('no_cashback')

const { value: selectedPayType } = useField<string | undefined>('payment_type')
const { value: cashback, errorMessage: cashbackError } = useField<number | undefined>('need_change')
const { value: selectedCardId } = useField<any | undefined>('card_id')

const showMyLocationDialog = ref(false)
const closeMyLocationDialog = () => {
  showMyLocationDialog.value = false
}
</script>
