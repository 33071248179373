<template>
  <HeadlessTransitionRoot
    appear
    :show="show"
    as="template"
  >
    <HeadlessDialog
      as="div"
      class="relative z-50"
      :initial-focus="dialogPanelEl"
      @close="emit('close')"
    >
      <HeadlessTransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25">
          <MouseFollower :hide-when-over-el="dialogPanelEl" />
        </div>
      </HeadlessTransitionChild>

      <div class="fixed bottom-0 left-0 top-0 w-screen overflow-y-auto overflow-x-hidden">
        <div class="flex h-full items-center justify-center text-center">
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-y-95 translate-y-16"
            enter-to="opacity-100 scale-y-100 translate-y-0"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-y-100 translate-y-0"
            leave-to="opacity-0 scale-y-95 translate-y-16"
          >
            <HeadlessDialogPanel class="h-full w-full transition-all">
              <div
                ref="dialogPanelEl"
                class="flex h-full w-full transform flex-col items-stretch overflow-hidden bg-whitegray"
              >
                <button
                  class="flex items-center gap-4 px-4 py-5 text-start text-xl font-medium text-black"
                  type="button"
                  @click="emit('close')"
                >
                  <IconArrowRight class="size-6 rotate-180 md:hidden" />
                  Добавить адрес
                </button>

                <div class="aspect-[15/8]">
                  <MyYandexMap
                    show-center-marker
                    :coordinates="coordinates"
                    @update-coords-drag="updateCoords"
                  />
                </div>

                <form
                  class="overflow-auto flex w-full grow flex-col items-stretch justify-between p-4"
                  @submit="onSubmit"
                >
                  <div class="flex shrink flex-col items-stretch gap-4">
                    <div>
                      <InputAutocomplete
                        v-model:query="query"
                        name="address"
                        class="z-20"
                        label="Город, улица и дом"
                        :options="data || []"
                        :is-loading="isLoadingAddressSearch"
                        :display-value="(option: any) => option.display_name"
                      />
                    </div>

                    <div class="flex gap-4">
                      <InputText
                        class="flex-1"
                        name="entrance"
                        label="Подьезд"
                      />
                      <InputText
                        class="flex-1"
                        name="door_phone"
                        label="Код двери"
                      />
                    </div>

                    <div class="flex gap-4">
                      <InputNumber
                        class="flex-1"
                        name="floor"
                        label="Этаж"
                      />
                      <InputText
                        class="flex-1"
                        name="apartment"
                        label="Номер квартиры"
                      />
                    </div>

                    <InputTextarea
                      name="comment"
                      label="Комментарий"
                      :rows="3"
                      disable-resize
                    />
                  </div>

                  <div>
                    <SimpleButton
                      class="w-full px-4 py-4 text-sm font-medium uppercase"
                      type="submit"
                      :disabled="isPendingAddAddress"
                    >
                      Сохранить
                    </SimpleButton>
                  </div>
                </form>
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
import { ref, toRefs } from 'vue'
import * as yup from 'yup'
import type { MyCoords } from '~/interfaces/common'

const props = defineProps<{
  show?: boolean
}>()
const { show } = toRefs(props)
const emit = defineEmits(['close'])

const { handleSubmit, setFieldValue } = useForm({
  validationSchema: yup.object({
      address: yup
      .object({
        lat: yup.string(),
        lon: yup.string(),
          street: yup.string(),
          house: yup.string(),
          city: yup.string(),
        display_name: yup.string(),
      })
      .default(undefined)
      .required()
      .label('Город, улица и дом'),
      entrance: yup.string().label('Подъезд'),
    door_phone: yup.string().label('Код двери'),
    floor: yup.string().label('Этаж'),
    apartment: yup.string().label('Номер квартиры'),
    comment: yup.string().label('Комментарий'),
  }),
})

const address = useFieldValue<{ lat: string; lon: string; display_name: string }>('address')
const coordinates = computed(() => {
  if (address.value) {
    return [Number(address.value.lon), Number(address.value.lat)]
  }
  return [37.617698, 55.755864]
})

const query = ref('')
const throttledQuery = refThrottled(query, 500, undefined, false)

const { data, isLoading: isLoadingAddressSearch } = useAddressSearch(throttledQuery, (v) => v, show)
const { mutateAsync, isPending: isPendingAddAddress } = useAddAddress()

const dialogPanelEl = ref<HTMLElement>()

const axiosPrivate = usePrivateAxiosInstance()
const updateCoords = (c: MyCoords) => {
  axiosPrivate
    .get('user/address/search/geo', {
      params: {
        latitude: c.Lat,
        longitude: c.Lng,
      },
    })
    .then((res) => {
      setFieldValue('address', res.data)
    })
}

const onSubmit = handleSubmit((vals: any) => {
  let fullName = vals.address.display_name
  if (vals.door_phone) fullName += `, код двери ${vals.door_phone}`
  if (vals.apartment) fullName += `, кв. ${vals.apartment}`
  if (vals.entrance) fullName += `, подъезд ${vals.entrance}`

  const body = {
      entrance: vals.entrance,
    door_phone: vals.door_phone,
    floor: vals.floor,
      apartment: vals.apartment,
    comment: vals.comment,
    latitude: vals.address.lat,
    longitude: vals.address.lon,
    address: vals.address.display_name,
      city: vals.address.city,
      house: vals.address.house,
      street: vals.address.street,
    full_name: fullName,
  }
  mutateAsync(body).then(() => emit('close'))
})
</script>
