<template>
  <div class="relative h-full w-full">
    <Transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="basket && basket.total_items > 0"
        class="relative flex h-full w-full transform flex-col items-start overflow-y-auto bg-white py-8"
      >
        <strong class="px-4 text-2xl font-medium">
          {{ basket?.total_items || 0 }} {{ pluralizedItemsInCartCountWord }} на
          <AnimatedNumber :number="basket?.total_price || 0" /> &#8381;
        </strong>

        <!--<p class="mt-2 px-4 opacity-50">{{ displayDeliveryCost }}</p>-->
        <div class="relative my-4 flex w-full grow flex-col items-stretch py-4">
          <ul class="px-4">
            <li
              v-for="position in basket?.items"
              :key="position.id"
              class="my-2 flex w-full gap-2 rounded-xl bg-white p-4 shadow-main"
            >
              <template v-if="!position.price">
                <img
                  class="h-full w-24 self-center object-contain object-center lg:h-24 lg:w-36"
                  :src="position.img"
                  alt=""
                />
                <div class="flex grow flex-col items-stretch justify-between self-stretch">
                  <div class="flex items-start justify-between">
                    <div>
                      <p class="text-black">{{ position.name }}</p>
                      <p class="text-sm text-black opacity-50">{{ position.weight }} гр</p>
                    </div>
                  </div>
                  <div class="flex items-end justify-end">
                    <span class="font-medium text-orange-200">Подарок к заказу</span>
                  </div>
                </div>
              </template>

              <template v-else>
                <img
                  class="h-full w-24 self-center object-contain object-center lg:h-24 lg:w-36"
                  :src="position.img"
                  alt=""
                />
                <div class="flex grow flex-col items-stretch justify-between self-stretch">
                  <div class="flex items-start justify-between">
                    <div>
                      <p class="text-black">{{ position.name }}</p>
                      <p class="text-sm text-black opacity-50">{{ position.weight }} гр</p>
                    </div>
                    <button
                      type="button"
                      @click="removeAll(position)"
                    >
                      <IconClose class="h-6" />
                    </button>
                  </div>
                  <div class="flex items-end justify-between">
                    <p class="mt-auto text-lg text-black">{{ position.price }} &#8381;</p>
                    <p v-if="position.discount > 0" class="mt-auto text-lg text-black">Скидка: {{ position.discount }} &#8381;</p>
                    <div>
                      <DishAdder
                        class="h-8 w-28 md:w-32"
                        hide-button
                        :dish-id="position.dish_id"
                        :dish-name="position.name"
                        :can-deliver="true"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </div>

        <div class="my-8 w-full px-4">
          <div class="flex w-full items-center justify-between rounded-lg p-4 shadow-elevated">
            <span>Укажите количество персон</span>
            <div class="h-8 w-28 md:w-32">
              <InputNumberAdder
                name="guests_count"
                :min="1"
                :max="99"
              />
            </div>
          </div>
        </div>

        <div class="w-full px-4 pt-4 font-medium shadow-main">
          <OrderDialogInputPromocode
            name="coupon"
            class="mb-4"
            placeholder="Промокод"
          />

            <!--
          <label class="mb-8 flex items-center justify-between gap-4">
            <div>
              <div>Вкусоины</div>
              <div class="text-sm font-normal text-black/50">
                <template v-if="basket.gifts.coins_can_use > 0">
                 Можно списать: {{basket.gifts.coins_can_use}}
                </template>
                <template v-else>
                 Нет бонусов для списания
                </template>
              </div>
            </div>
            <InputSwitch name="use_coins" :disabled="basket.gifts.coins_can_use < 1" />
          </label>-->

            <div class="mb-8 mt-8" v-if="loyaltyPrograms && loyaltyPrograms.length > 0">
                <div>Выберите акцию</div>

                <HeadlessListbox v-model="selectedLoyaltyProgram">
                    <div class="relative mt-1">
                        <HeadlessListboxButton
                            class="relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                        >
                            <span class="block truncate">{{ selectedLoyaltyProgram?.name }}</span>
                            <span
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                            >
                                <ChevronDownIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
            </span>
                        </HeadlessListboxButton>

                        <transition
                            leave-active-class="transition duration-100 ease-in"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <HeadlessListboxOptions
                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                                <HeadlessListboxOption
                                    v-for="program in loyaltyPrograms"
                                    v-slot="{ active, selected }"
                                    :key="program.id"
                                    :value="program"
                                    as="template"
                                >
                                    <li
                                        :class="[
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                  ]"
                                    >
                  <span
                      :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                  >{{ program.name }}
                  <span v-if="program.bonuses_award_sum > 0">
                      (начислим: {{program?.bonuses_award_sum}})
                  </span>
                  <span v-if="program.bonuses_available_to_spend > 0">
                      (можно потратить: {{program.bonuses_available_to_spend}})
                  </span>
                  <span v-if="program.total_discount > 0">
                      (общая скидка: {{program.total_discount}} руб.)
                  </span>
                  </span>
                                        <span
                                            v-if="selected"
                                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                        >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                                    </li>
                                </HeadlessListboxOption>
                            </HeadlessListboxOptions>
                        </transition>
                    </div>
                </HeadlessListbox>

                <div v-if="selectedLoyaltyProgram" class="mt-4">
                    <div v-if="selectedLoyaltyProgram.bonuses_award_sum > 0">
                        Начислим {{selectedLoyaltyProgram.bonuses_award_sum}} бонусов
                    </div>
                    <div v-if="selectedLoyaltyProgram.bonuses_available_to_spend > 0">
                        <InputNumber
                            name="spend"
                            :label="'Укажите, сколько бонусов Вы хотите списать. Максимум:' + selectedLoyaltyProgram.bonuses_available_to_spend"
                            :min="1"
                            :max="selectedLoyaltyProgram.bonuses_available_to_spend"
                        />
                    </div>
                </div>
            </div>

          <div
            v-if="basket.total_discount"
            class="flex items-center justify-between"
          >
            <span>Скидка</span>
            <span><AnimatedNumber :number="basket.total_discount" /> &#8381;</span>
          </div>

          <div class="flex items-center justify-between">
            <span>{{ basket?.total_items }} {{ pluralizedItemsInCartCountWord }}</span>
            <span><span v-if="basket.total_discount > 0" class="line-through opacity-30 pr-2">{{basket?.total_price || 0}}</span><AnimatedNumber :number="(basket?.total_price - basket?.total_discount) || 0" /> &#8381;</span>
          </div>

          <div class="flex items-center justify-between">
            <span>Доставка</span>
            <span><AnimatedNumber :number="basket.delivery_price" /> &#8381;</span>
          </div>

          <SimpleButton
            :disabled="basket?.total == 0"
            class="mt-8 w-full px-4 py-4 text-xs uppercase"
            type="button"
            @click="emit('proccedToPayment')"
          >
            Перейти к оформлению <IconArrowRight class="inline h-4" />
          </SimpleButton>
        </div>
      </div>

      <div
        v-else
        class="flex h-full items-center justify-center"
      >
        <div
          class="relative flex max-w-xs flex-col items-center rounded-xl bg-blue-100 px-1 pb-20 pt-12 text-center lg:max-w-sm lg:pb-24"
        >
          <img
            class="h-12 lg:h-14"
            src="/fish.svg"
            alt=""
          />
          <strong class="mt-2 text-base font-bold uppercase lg:text-xl"> ой, кажется, Корзина пуста </strong>
          <p class="mt-4 text-sm">Ваша корзина пуста, откройте «Меню» и выберите понравившийся товар</p>
          <p class="mt-2 text-sm">Мы доставим ваш заказ от {{ main?.from_deliver }} ₽</p>

          <img
            src="/upset-cup.svg"
            class="absolute top-0 h-24 -translate-y-3/4 transition-opacity lg:h-32"
            :class="isCupReady ? 'opacity-100' : 'opacity-0'"
            alt=""
          />

          <img
            src="/upset-sushi.svg"
            class="absolute bottom-0 h-32 max-w-none translate-y-1/2 transition-opacity lg:h-40"
            :class="isSushiReady ? 'opacity-100' : 'opacity-0'"
            alt=""
          />
        </div>
      </div>
    </Transition>

    <span class="absolute left-0 right-0 top-2 text-center text-sm text-black opacity-50 md:hidden">
      Свайпай вправо, чтобы закрыть
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useImage } from '@vueuse/core'
import AnimatedNumber from '~/components/AnimatedNumber.vue'
import type { DishInBasket } from '~/interfaces/main'
import { type LoyaltyProgram, type LoyaltyPrograms, useLoyaltyPrograms } from '~/composables/api/basket'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['proccedToPayment', 'cartItemsUpdated'])

const coupon = useFieldValue<string>('coupon')

const selectedLoyaltyProgram = ref<LoyaltyProgram>()

const { value: loyaltyProgramId } = useField<string>('loyalty_program_id')

watch(selectedLoyaltyProgram, () => {
    if (selectedLoyaltyProgram.value?.id) {
        loyaltyProgramId.value = selectedLoyaltyProgram.value?.id
    }
})

const enabled = computed(() => !!selectedLoyaltyProgram.value?.id);

// const { data: user } = useUser((v) => v)
const { data: basket } = useBasketWithGifts((v) => {
    emit('cartItemsUpdated', v)

    return v
}, { enabled, coupon, loyalty_program_id: loyaltyProgramId })

const { data: loyaltyPrograms } = useLoyaltyPrograms((v) => {
    if (v.length > 0) {
        ///let selectProgram = {id: "", name: "Выберите акцию", bonuses_award_sum: 0},
        //    programs = [selectProgram]
        //programs = [...programs, ...v]
        selectedLoyaltyProgram.value = v[0]
    }

    return v
}, { enabled: true, coupon })

const { data: main } = useMain((v) => v)

const pluralizedItemsInCartCountWord = computed(() => {
  if (basket.value?.total_items === 1) return 'товар'
  if (basket.value?.total_items === 2) return 'товара'
  if (basket.value?.total_items === 3) return 'товара'
  if (basket.value?.total_items === 4) return 'товара'
  return 'товаров'
})
/*
const displayDeliveryCost = computed(() => {
  if (main.value && basket.value) {
    if (basket.value.total_sum > main.value.from_deliver) {
      return 'Бесплатная доставка'
    } else {
      return `Стоимость доставки ${main.value.deliver_price} ₽`
    }
  }
  return 'Нет данных'
})*/

const { mutate } = useAddToBasket()

const removeAll = (position: DishInBasket) => {
  mutate({
    id: position.id,
    quantity: 0,
    dish_id: position.dish_id,
    dish_name: position.name,
  })
}

const { isReady: isCupReady } = useImage({ src: '/upset-cup.svg' })
const { isReady: isSushiReady } = useImage({ src: '/upset-sushi.svg' })
</script>
