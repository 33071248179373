<template>
  <HeadlessTransitionRoot
    appear
    :show="show"
    as="template"
  >
    <HeadlessDialog
      as="div"
      class="relative z-50"
      @close="emit('close')"
    >
      <HeadlessTransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25">
          <MouseFollower :hide-when-over-el="dialogPanelEl" />
        </div>
      </HeadlessTransitionChild>

      <div class="fixed bottom-0 left-0 top-0 w-screen overflow-y-auto overflow-x-hidden">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <HeadlessDialogPanel class="w-full max-w-5xl rounded-2xl shadow-xl transition-all">
              <div
                ref="dialogPanelEl"
                class="flex w-full transform items-stretch justify-between overflow-hidden rounded-2xl bg-whitegray"
              >
                <ClientOnly>
                  <form
                    class="flex w-full flex-col items-stretch justify-between p-8"
                    @submit="onSubmit"
                  >
                    <div class="flex shrink flex-col items-stretch gap-4">
                      <h1 class="mb-4 text-start text-xl font-medium text-black">Изменить адрес</h1>
                      <div>
                        <InputAutocomplete
                          v-model:query="query"
                          name="address"
                          class="z-20"
                          label="Город, улица и дом"
                          :options="data || []"
                          :is-loading="isLoadingAddressSearch"
                          :display-value="(option: any) => option.display_name"
                        />
                      </div>

                      <div class="flex gap-4">
                        <InputText
                          class="flex-1"
                          name="entrance"
                          label="Подьезд"
                        />
                        <InputText
                          class="flex-1"
                          name="door_phone"
                          label="Код двери"
                        />
                      </div>

                      <div class="flex gap-4">
                        <InputNumber
                          class="flex-1"
                          name="floor"
                          label="Этаж"
                        />
                        <InputText
                          class="flex-1"
                          name="house"
                          label="Номер квартиры"
                        />
                      </div>

                      <InputTextarea
                        name="comment"
                        label="Комментарий"
                        :rows="3"
                        disable-resize
                      />
                    </div>

                    <div class="flex gap-4">
                      <button
                        class="flex-1 rounded-xl bg-gray px-4 py-4 outline-none ring-gray ring-offset-2 transition-shadow focus:ring-2"
                        type="button"
                        @click="deleteAddress(props.address.id)"
                      >
                        <span class="font-medium text-black opacity-50">Удалить</span>
                      </button>
                      <SimpleButton
                        class="w-full flex-1 px-4 py-4 text-sm font-medium uppercase"
                        type="submit"
                        :disabled="isPendingUpdateAddress"
                      >
                        Сохранить
                      </SimpleButton>
                    </div>
                  </form>

                  <div class="aspect-square h-[36rem] shrink-0 overflow-hidden rounded-xl">
                    <MyYandexMap
                      :coordinates="coordinates"
                      show-center-marker
                      @update-coords-drag="updateCoords"
                    />
                  </div>
                </ClientOnly>
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
import { ref, toRefs } from 'vue'
import * as yup from 'yup'
import type { Address } from '~/interfaces/main'
import type { MyCoords } from '~/interfaces/common'


const props = defineProps<{
  show?: boolean
  address: Address
}>()
const { show } = toRefs(props)
const emit = defineEmits(['close'])

const dialogPanelEl = ref<HTMLElement>()

const { handleSubmit, setFieldValue } = useForm<any>({
  validationSchema: yup.object({
    address: yup
      .object({
        lat: yup.string(),
        lon: yup.string(),
          street: yup.string(),
          house: yup.string(),
          city: yup.string(),
        display_name: yup.string(),
      })
      .default(undefined)
      .required()
      .label('Город, улица и дом'),
      entrance: yup.string().label('Подъезд'),
    door_phone: yup.string().label('Код двери'),
    floor: yup.string().label('Этаж'),
      apartment: yup.string().label('Номер квартиры'),
    comment: yup.string().label('Комментарий'),
  }),
  initialValues: computed(() => ({
      address: {
      lat: props.address.latitude,
      lon: props.address.longitude,
      display_name: props.address.full,
          street: props.address.street,
          house: props.address.house,
          city: props.address.city,
    },
      entrance: props.address.entrance,
    door_phone: props.address.door_phone,
    floor: props.address.floor,
      apartment: props.address.apartment,
    comment: props.address.comment,
  })),
  keepValuesOnUnmount: true,
})

const address = useFieldValue<{ lat: string; lon: string; display_name: string }>('address')
const coordinates = computed(() => {
  if (address.value) {
    return [Number(address.value.lon), Number(address.value.lat)]
  }
  return [37.617698, 55.755864]
})

const axiosPrivate = usePrivateAxiosInstance()

const updateCoords = (c: MyCoords) => {
  axiosPrivate
    .get('user/address/search/geo', {
      params: {
        latitude: c.Lat,
        longitude: c.Lng,
      },
    })
    .then((res) => {
      setFieldValue('address', res.data)
    })
}

const query = ref('')
const throttledQuery = refThrottled(query, 500, undefined, false)

const { data, isLoading: isLoadingAddressSearch } = useAddressSearch(throttledQuery, (v) => v, show)
const { mutateAsync: updateAddressAsync, isPending: isPendingUpdateAddress } = useUpdateAddress()
const { mutateAsync: deleteAddressAsync } = useDeleteAddress()

const onSubmit = handleSubmit((vals: any) => {
  let fullName = vals.address.display_name
  if (vals.door_phone) fullName += `, код двери ${vals.door_phone}`
  if (vals.apartment) fullName += `, кв. ${vals.apartment}`
  if (vals.entrance) fullName += `, подъезд ${vals.entrance}`

  const body = {
    id: props.address.id,

      entrance: vals.entrance,
    door_phone: vals.door_phone,
    floor: vals.floor,
    apartment: vals.apartment,
    comment: vals.comment,
    latitude: vals.address.lat,
    longitude: vals.address.lon,
      address: vals.address.display_name,
      city: vals.address.city,
      house: vals.address.house,
      street: vals.address.street,

    full_name: fullName,
  }
  updateAddressAsync(body).then(() => emit('close'))
})

const deleteAddress = (addressID: number) => {
  deleteAddressAsync(addressID).then(() => emit('close'))
}
</script>
